import * as React from 'react'
import { graphql } from 'gatsby'
import {
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  useTabsContext,
} from '@reach/tabs'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'
import { useStyles } from 'react-treat'
import { Box, useBoxStyles } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'
import VisuallyHidden from '@reach/visually-hidden'
import ReactPlayer from 'react-player/youtube'
import clsx from 'clsx'

import { ProfileBodyVideosFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { ProfileTemplateEnhancerProps } from '../templates/profile'

import { BoundedBox } from '../components/BoundedBox'
import { Columns } from '../components/Columns'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Divider } from '../components/Divider'
import { Text } from '../components/Text'
import { Icon } from '../components/Icon'

import * as styleRefs from './ProfileBodyVideos.treat'

type VideoPanelProps = {
  index: number
  videoUrl?: string
  posterFluid?: FluidObject
  posterAlt?: string
}

// Sorry, this doesn't use the VideoPlayer component. If you have time, feel
// free to convert it. VideoPlayer was made by copy/pasting this component and
// removing the tab-specific functionality.
// - AA 2020-09-16
const VideoTabPanel = ({
  index,
  videoUrl,
  posterFluid,
  posterAlt,
}: VideoPanelProps) => {
  const styles = useStyles(styleRefs)

  const { selectedIndex } = useTabsContext()
  const isSelected = index === selectedIndex

  const [isPlaying, setPlaying] = React.useState(false)
  const play = () => setPlaying(true)
  const stop = () => setPlaying(false)
  const pause = stop

  const [hasStarted, setHasStarted] = React.useState(false)

  const onStart = () => setHasStarted(true)
  const onPlay = play
  const onPause = pause
  const onEnded = () => {
    setHasStarted(false)
    stop()
  }

  const hideWhenPlaying = useBoxStyles({
    opacity: isPlaying ? 0 : 100,
    transitionDuration: 'normal',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'easeOut',
  })
  const fullSize = useBoxStyles({
    height: 'full',
    width: 'full',
  })
  const positionRelative = useBoxStyles({ position: 'relative' })

  React.useEffect(() => {
    if (!isSelected) pause()
  }, [isSelected, pause])

  return (
    <AspectRatio x={16} y={9} className={positionRelative}>
      <Box
        component="button"
        onClick={play}
        className={styles.buttonFocusCapture}
        styles={{ height: 'full', width: 'full' }}
      >
        <Box
          styles={{
            backgroundColor: 'black',
            opacity: hasStarted ? 0 : 100,
            pointerEvents: hasStarted ? 'none' : 'auto',
            transitionDuration: 'normal',
            transitionProperty: 'opacity',
            transitionTimingFunction: 'easeOut',
            position: 'absolute',
            width: 'full',
            height: 'full',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <VisuallyHidden>Play video</VisuallyHidden>
          {posterFluid && (
            <GatsbyImage
              fluid={posterFluid}
              alt={posterAlt}
              className={clsx(fullSize, hideWhenPlaying)}
            />
          )}
          {videoUrl && (
            <Box
              className={clsx(
                hideWhenPlaying,
                styles.buttonFocusPlayButtonTarget,
              )}
              styles={{
                paddingTop: [4, 6],
                paddingBottom: [4, 6],
                paddingLeft: [7, 11],
                paddingRight: [7, 11],
                position: 'absolute',
                transitionDuration: 'normal',
                transitionProperty: 'backgroundColor',
                transitionTimingFunction: 'easeOut',
              }}
            >
              <Icon
                name="play"
                className={styles.offsetIcon}
                styles={{ color: 'white', width: ['1.5rem', '2rem'] }}
              />
            </Box>
          )}
        </Box>
        {videoUrl && (
          <ReactPlayer
            url={videoUrl}
            playing={isPlaying}
            controls={true}
            onStart={onStart}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            width="100%"
            height="100%"
            config={{
              youtube: {
                embedOptions: {
                  modestbranding: 1,
                },
              },
            }}
          />
        )}
      </Box>
    </AspectRatio>
  )
}

type VideoTabProps = {
  index: number
  name?: string
  thumbnailFluid?: FluidObject
  thumbnailAlt?: string
}

const VideoTab = ({
  index,
  name,
  thumbnailFluid,
  thumbnailAlt,
}: VideoTabProps) => {
  const { selectedIndex } = useTabsContext()
  const isSelected = index === selectedIndex

  return (
    <Box styles={{ position: 'relative' }}>
      {thumbnailFluid && (
        <GatsbyImageContainer
          styles={{
            marginBottom: [4, 5, 6],
            borderWidth: ['2px', '3px'],
            borderColor: isSelected ? 'fuschia30' : 'transparent',
            borderStyle: 'solid',
            marginLeft: ['-2px', '-3px'],
            marginRight: ['-2px', '-3px'],
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            transitionProperty: 'borderColor',
          }}
          focusStyles={{ borderColor: isSelected ? undefined : 'beige80' }}
          hoverStyles={{ borderColor: isSelected ? undefined : 'beige80' }}
        >
          <AspectRatio x={16} y={9}>
            <GatsbyImage fluid={thumbnailFluid} alt={thumbnailAlt} />
          </AspectRatio>
        </GatsbyImageContainer>
      )}
      {name && (
        <Text
          variant="sans-caps-semibold-13-18"
          component="span"
          styles={{ display: 'block' }}
        >
          {name}
        </Text>
      )}
    </Box>
  )
}

export type ProfileBodyVideosProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const ProfileBodyVideos = ({
  children,
  nextSharesBg,
  withBottomDivider,
}: ProfileBodyVideosProps) => {
  const hasMultipleVideos = React.Children.count(children) > 1

  const fullWidth = useBoxStyles({ width: 'full' })

  return (
    <BoundedBox
      component="section"
      variant="wide"
      nextSharesBg={nextSharesBg}
      styles={{
        backgroundColor: 'white',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Tabs>
        <TabPanels>
          {React.Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) return null

            const props = child.props as ProfileBodyVideosVideoProps

            return (
              <TabPanel>
                <VideoTabPanel
                  key={props.name}
                  index={index}
                  videoUrl={props.videoUrl}
                  posterFluid={props.thumbnailFluid}
                  posterAlt={props.thumbnailAlt}
                />
              </TabPanel>
            )
          })}
        </TabPanels>
        {hasMultipleVideos && (
          <Box styles={{ marginTop: [6, 9, 11] }}>
            <TabList>
              <Columns space={[4, 5, 6]} count={[2, 4, 6]} align="center">
                {React.Children.map(children, (child, index) => {
                  if (!React.isValidElement(child)) return null

                  const props = child.props as ProfileBodyVideosVideoProps

                  return (
                    <Tab className={fullWidth}>
                      <VideoTab
                        key={props.name}
                        index={index}
                        name={props.name}
                        thumbnailFluid={props.thumbnailFluid}
                        thumbnailAlt={props.thumbnailAlt}
                      />
                    </Tab>
                  )
                })}
              </Columns>
            </TabList>
          </Box>
        )}
      </Tabs>
      {withBottomDivider && (
        <Divider
          color="gold70"
          styles={{ height: '2px', marginTop: [10, 18, 26] }}
        />
      )}
    </BoundedBox>
  )
}

type ProfileBodyVideosVideoProps = {
  name?: string
  videoUrl?: string
  thumbnailFluid?: FluidObject
  thumbnailAlt?: string
}

const ProfileBodyVideosVideo = (_props: ProfileBodyVideosVideoProps) => null
ProfileBodyVideos.Video = ProfileBodyVideosVideo

export const mapDataToProps = ({
  data,
  context,
  nextContext,
}: MapDataToPropsArgs<ProfileBodyVideosFragment, typeof mapDataToContext>) => ({
  withBottomDivider:
    (data.items?.length ?? 0) > 1 && context?.bg === nextContext?.bg,
  children: data.items?.map?.((item) => (
    <ProfileBodyVideos.Video
      key={item?.name?.text}
      name={item?.name?.text}
      videoUrl={item?.video?.embed_url}
      thumbnailFluid={item?.thumbnail?.fluid}
      thumbnailAlt={item?.thumbnail?.alt}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment ProfileBodyVideos on PrismicProfileBodyVideos {
    items {
      name {
        text
      }
      video {
        embed_url
      }
      thumbnail {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default ProfileBodyVideos
