import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { withPreview } from 'gatsby-source-prismic'
import { propPairsEq } from '@walltowall/helpers'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'

import { ProfileTemplateQuery } from '../graphqlTypes'
import { MapDataToPropsEnhancerArgs, PickPartial } from '../types'
import { slicesMap as profileBodySlicesMap } from '../slices/ProfileBody'
import { slicesMap as pageBodySlicesMap } from '../slices/PageBody'

import { Layout } from '../components/Layout'
import { useSiteSettings } from '../hooks/useSiteSettings'

const slicesMap = {
  ...pageBodySlicesMap,
  ...profileBodySlicesMap,
}

/**
 * `listMiddleware` for `react-map-slices-to-components`. Add or modify slices
 * for the profile here.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#change-the-list-of-slices
 */
// prettier-ignore
export const slicesMiddleware = <T,>(list: T[]) => [
  { __typename: 'PageBodyHeader', id: 'header' },
  ...list,
  {
    __typename: 'PageBodyCallToAction',
    id: 'cta',
    primary: {
      text: {
        text: 'Placeholder',
        html:
          '<h1>Ready to get started?</h1><p>Tell us a little about yourself and let us help you take your business to the next level.</p>',
      },
    },
    items: [
      {
        button_text: { text: 'Call Us' },
        button_link: { url: 'tel:+18085287711' },
      },
      {
        button_text: { text: 'Visit Us' },
        button_link: { url: 'https://hawaiinational.bank/locations/' },
      },
      {
        button_text: { text: 'Email Us' },
        button_link: { url: 'mailto:eBanking@HNBhawaii.com' },
      },
    ],
  },
  { __typename: 'PageBodyFooter', id: 'footer' },
]

/**
 * `mapDataToPropsEnhancer` for `react-map-slices-to-components`. Props defined
 * here are added to all slices.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#providing-global-enhancers
 */
export const mapDataToPropsEnhancer = (
  props: object | undefined,
  {
    context,
    nextContext,
    previousType,
    previousData,
    type,
    nextType,
  }: MapDataToPropsEnhancerArgs,
) => {
  let nextSharesBg

  // TODO: Clean up into a nicer helper function
  const _nsbg = propPairsEq('bg', context, nextContext)
  if (_nsbg.length === 1) nextSharesBg = _nsbg[0]
  else nextSharesBg = _nsbg.slice(0, 4) as [boolean, boolean, boolean, boolean]

  return {
    nextSharesBg,
    id:
      previousType === 'ProfileBodyAnchor'
        ? (previousData?.primary?.id as string)
        : undefined,
    ...props,
  }
}

/**
 * Props added to all slices by `mapDataToPropsEnhancer` for `ProfileTemplate`.
 * Intersect this type with a slice's known props to get a complete list of
 * available props.
 *
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#intersection-types
 */
export type ProfileTemplateEnhancerProps = PickPartial<
  ReturnType<typeof mapDataToPropsEnhancer>,
  'id'
>

export const ProfileTemplate = ({
  data,
  location,
}: PageProps<ProfileTemplateQuery>) => {
  const siteSettings = useSiteSettings()
  const profile = data?.prismicProfile

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  const meta = React.useMemo(
    () => ({
      rootData: data,
      location,
    }),
    [data, location],
  )

  return (
    <Layout>
      <Helmet>
        <title>
          {profile?.data?.meta_title ??
            profile?.data?.profile_title?.text ??
            profile?.data?.title?.text ??
            ''}{' '}
          | {siteSettings.siteName}
        </title>
        {profile?.data?.meta_description && (
          <meta name="description" content={profile?.data?.meta_description} />
        )}
      </Helmet>
      <MapSlicesToComponents
        list={profile?.data?.body}
        map={slicesMap}
        meta={meta}
        listMiddleware={slicesMiddleware}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      />
    </Layout>
  )
}

export default withPreview(ProfileTemplate)

export const query = graphql`
  query ProfileTemplate($uid: String!) {
    prismicProfile(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title {
          text
        }
        meta_title
        meta_description
        profile_title {
          text
        }
        body {
          __typename
          ... on Node {
            id
          }
          ...SlicesProfileBody
        }
      }
    }
  }
`
